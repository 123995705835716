



import Vue from 'vue';

import { commonModule } from '@/store/viewModule/common/commonModule';
import LocalDataService from '@/service/LocalDataServices';

export default Vue.extend({
  async mounted() {
    // const authType = LocalDataService.getAuthType();
    // if (authType) {
    //   const isSessionValid = await commonModule.checkSession();
    //   if (isSessionValid) {
    //     this.$router.push('/today');
    //     return;
    //   }
    // }
    // this.$router.push('/signin');
  },
});
